.spinner-main-widget {
  flex-direction: column;
  display: flex;
  height: 200px;
  justify-content: center;
  align-items: center;
}
.loader-widget {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.negative-balance {
  color: red;
}
.spinner-main-widget p {
  margin-top: 10px; /* Menambahkan jarak di atas teks */
  color: #333;
  font-size: 14px;
  font-weight: bold;
}
.loader-widget > div {
  width: 10px;
  height: 10px;
  background-color: #4154f1;
  margin: 0 3px;
  border-radius: 100%;
  display: inline-block;
  animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}

.loader-widget .bounce1-widget {
  animation-delay: -0.32s;
}

.loader-widget .bounce2-widget {
  animation-delay: -0.16s;
}

@keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    transform: scale(0);
  }
  40% {
    transform: scale(1);
  }
}


