.container-tenant {
  background-image: url("../../assets/images/background.jpg");
  background-size: cover;
  background-position: center;
}

.logout-tenant {
  color: #f6f9ff;
  margin-top: 12px;
}

.logout-tenant span {
  font-size: 10px;
}

.logout-tenant .icon-logout {
  font-size: 10px;
  color: #f6f9ff;
  margin-left: 5px;
}
.logout-tenant .icon-logout a {
  color: #f6f9ff;
}

.logo-header-tenant {
  display: flex;
  align-items: center;
}

.logo-image {
  width: 32px;
  margin-right: 10px;
}

.logo-texts {
  display: flex;
  color: #f6f9ff;
  flex-direction: column;
  align-items: flex-start;
}

.logo-text {
  font-size: 14px;
}

.logo-subtext {
  font-size: 18px;
  font-weight: bold;
}

.vertical-line {
  height: 2rem; /* Anda bisa menyesuaikan tingginya sesuai kebutuhan */
  width: 1px; /* Lebar garis */
  background-color: grey; /* Warna garis. Anda bisa menggantinya */
  margin: 0px 10px; /* Margin di sekitar garis untuk memberikan jarak */
  margin-left: 20px;
}

.header-desc {
  color: #f6f9ff;
  font-size: 14px;
}

.form-tenant {
  width: 100%;
  height: 100%;
  background-color: #f6f9ff;
}

.section-header {
  margin-top: 30px;
  font-size: 10px;
}

.form-vertical {
  margin-top: 20px;
}

.required {
  color: red;
}

#btn-submit {
  background-color: #717ff5;
  color: #f6f9ff;
}

#btn-submit:hover {
  background-color: #012970;
  color: #f6f9ff;
}
