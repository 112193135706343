.register .logo-text {
  color: #f6f9ff;
}
.register a {
  color: #f6f9ff;
}

.credits {
  color: #f6f9ff;
}
