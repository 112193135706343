.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 30px;
  background-color: #fff;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  background-image: url("../../assets/images/background.jpg");
  background-size: cover;
  background-position: center;
}

.header-left {
  display: flex;
  align-items: center;
}

.header-logo {
  width: auto;
  height: 2rem;
  margin-right: 20px;
}

.header-search input {
  padding: 8px 15px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.header-right {
  display: flex;
  align-items: center;
}

.profile-button {
  margin-left: 20px;
  padding: 8px 15px;
  border: none;
  background-color: #007bff;
  color: #fff;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.profile-button:hover {
  background-color: #0056b3;
}
.suggestions {
  background: #fff;
  text-align: left;
  position: absolute;
  top: 50px;
  border: 0;
  font-size: 14px;
  color: #007bff;
  transition: 0.3s;
  width: 320px;
}
.suggestions ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2);
  max-height: 200px;
  overflow-y: auto;
}
.suggestions ul.has-suggestions {
  display: block;
}
.suggestions ul li {
  padding: 10px;
  cursor: pointer;
  background: rgba(255, 255, 255, 0.2);
}
.suggestions ul li a {
  color: black;
}
.suggestions ul li:hover {
  color: #007bff;
  background-color: #f6f6f6;
}
.suggestions ul li span {
  color: #B6BBC4;
  font-weight: 200;
  font-style: italic;
  font-size: 12px;
}
