.spin-animation {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
  }
  
  .icon-spin {
    margin-top: 10px;
  }
  
  .spinner {
    display: flex;
    justify-content: space-between;
    width: 50px;
  }
  
  .bar {
    height: 10px;
    width: 10px;
    background-color: #4154f1; /* Warna garis loading */
    animation: fill 1s infinite;
  }
  
  .bar1 {
    animation-delay: 0.1s;
  }
  
  .bar2 {
    animation-delay: 0.2s;
  }
  
  .bar3 {
    animation-delay: 0.3s;
  }
  
  @keyframes fill {
    0% {
      height: 10px;
      width: 10px;
    }
    25% {
      height: 10px;
      width: 30px; /* Sesuaikan dengan kecepatan mengisi */
    }
    50% {
      height: 10px;
      width: 10px;
    }
    100% {
      height: 10px;
      width: 10px;
    }
  }
  