.summary-card {
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
}

.summary-card .card-body {
  padding: 1rem;
}

.summary-title, .summary-value {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 0.5rem;
}

.summary-title {
  font-weight: 500;
  color: #6c757d;
  flex: 1; /* Makes title take the remaining space */
}

.summary-value {
  font-weight: 600;
  color: #000;
}

/* Hanya menargetkan baris dalam summary-card dengan kelas summary-row */
.summary-card .summary-row:not(:last-child) {
  border-bottom: 1px solid #e9ecef;
  margin-bottom: 0.5rem;
}


/* Adjusts the space between title and value */
.col-auto {
  padding-left: 0;
}
