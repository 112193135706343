.ag-theme-material {
  --ag-header-foreground-color: #f6f6f6;
  --ag-header-background-color: #4154f1;
  --ag-header-cell-hover-background-color: #aab7cf;
  --ag-checkbox-unchecked-colorr: #f6f6f6;
  --ag-header-cell-moving-background-color: #4154f1;
}
.ag-theme-material .ag-header-cell .ag-header-cell-filtered {
  font-size: 12px;
}

.action-button a {
  color: #fff;
}

.action-button a:hover {
  color: #f6f6f6;
}

.spinner-main {
  flex-direction: column;
  display: flex;
  height: 80vh;
  justify-content: center;
  align-items: center;
}
.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.spinner-main p {
  margin-top: 10px; /* Menambahkan jarak di atas teks */
  color: #333;
  font-size: 14px;
  font-weight: bold;
}
.loader > div {
  width: 10px;
  height: 10px;
  background-color: #4154f1;
  margin: 0 3px;
  border-radius: 100%;
  display: inline-block;
  animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}

.loader .bounce1 {
  animation-delay: -0.32s;
}

.loader .bounce2 {
  animation-delay: -0.16s;
}

@keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    transform: scale(0);
  }
  40% {
    transform: scale(1);
  }
}
