.switch {
  position: relative;
  display: inline-block;
  width: 40px; /* Lebih kecil dari sebelumnya */
  height: 24px; /* Lebih kecil dari sebelumnya */
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: 24px; /* Lingkaran penuh ketika kecil */
}

.slider:before {
  position: absolute;
  content: "";
  height: 18px; /* Lebih kecil dari sebelumnya */
  width: 18px; /* Lebih kecil dari sebelumnya */
  left: 3px;
  bottom: 3px;
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: #2196f3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(16px);
  -ms-transform: translateX(16px);
  transform: translateX(16px); /* Sesuaikan jarak geser dengan lebar switch */
}

#dropdown-button-drop {
  border-color: #333;
  background-color: #f6f6f6;
}
.no-chevron-dropdown .dropdown-toggle::after {
  display: none;
}

#dropdown-button-drop:hover {
  border-color: #333;
  background-color: #ccc;
}

.no-chevron-dropdown .dropdown-item {
  color: #333;
  background-color: #fff;
}
.no-chevron-dropdown .dropdown-item:hover {
  background-color: #2196f3; /* Warna latar belakang saat fokus */
  color: #f6f6f6; /* Warna teks saat fokus */
  border-radius: 1rem;
}
