.print-section {
  visibility: hidden;
  position: absolute;
  left: 0;
  top: 0;
}
.lunas-overlay {
  position: absolute;
  top: 70%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
}
.lunas-overlay img {
  max-width: 100px; /* atau ukuran lain yang sesuai */
  max-height: 100px; /* atau ukuran lain yang sesuai */
  width: auto;
  height: auto;
  object-fit: contain; /* Ini akan memastikan gambar tidak terdistorsi */
}
