.invoice-box {
  max-width: 800px;
  margin: auto;
  padding: 30px;
  border: 1px solid #eee;
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
  font-size: 16px;
  line-height: 24px;
  font-family: "Helvetica Neue", "Helvetica", Helvetica, Arial, sans-serif;
  color: #000;
}

/* Common table styles */
.invoice-box table {
  width: 100%;
  line-height: inherit;
  text-align: left;
}
.title {
  width: 170px;
  height: 50px;
}
.logo-tittle img {
  width: 100px;
}
.logo-tittle small {
  margin-bottom: 10px;
  font-size: 10px;
  font-weight: bolder;
}
.title-company {
  display: flex;
  height: 150px;
  justify-content: center;
  align-items: center;
}
.title-company h4 {
  font-weight: bold;
}
.vendor-section {
  border-top: 1px solid #000; /* 1px solid black border on the top */
  border-bottom: 1px solid #000;
}
.info-faktur-detail {
  border: 1px solid;
}
.table .me-5 {
  margin-right: 5%;
}
.invoice-box table td {
  padding: 5px;
  vertical-align: top;
}

.invoice-box table tr.top table td {
  padding-bottom: 20px;
}

.invoice-box table tr.top table td.title {
  font-size: 45px;
  line-height: 45px;
  color: #333;
}

.table .span-info {
  width: 150px;
}
.sign-section .border-bottom {
  margin-top: 150px;
}
.aggre {
  padding-left: 50px;
}
.invoice-box table tr.information table td {
  padding-bottom: 40px;
}

.invoice-box table tr.heading td {
  background: #eee;
  border-bottom: 1px solid #ddd;
  font-weight: bold;
}

.invoice-box table tr.details td {
  padding-bottom: 20px;
}

.invoice-box table tr.item td {
  border-bottom: 1px solid #eee;
}

.invoice-box table tr.item.last td {
  border-bottom: none;
}

.invoice-box table tr.total td:nth-child(2) {
  border-top: 2px solid #eee;
  font-weight: bold;
}

@media print {
  @page {
    size: A4 portrait;
    margin: 0mm; /* Remove default page margin */
  }
  .invoice-box {
    width: 100%;
    max-width: 210mm; /* A4 width */
    margin: 10mm auto; /* Add some margin to the top and bottom */
    box-shadow: none; /* Remove box shadow in print */
  }


  .invoice-box table {
    width: 100%;
    line-height: inherit;
    text-align: left;
  }
  .title {
    width: 170px;
    height: 50px;
  }
  .logo-tittle img {
    width: 100px;
  }
  .logo-tittle small {
    margin-bottom: 10px;
    font-size: 10px;
    font-weight: bolder;
  }
  .title-company {
    display: flex;
    height: 150px;
    justify-content: center;
    align-items: center;
  }
  .title-company h4 {
    font-weight: bold;
  }
  .vendor-section {
    border-top: 1px solid #000; /* 1px solid black border on the top */
    border-bottom: 1px solid #000;
  }
  .info-faktur-detail {
    border: 1px solid;
  }
  .table .me-5 {
    margin-right: 5%;
  }
  
}
