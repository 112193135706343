.modal-backdrop {
    position: fixed; /* Stay in place */
    z-index: 1040; /* Sit on top */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0,0,0); /* Fallback color */
    background-color: rgba(0,0,0,0.5); /* Black w/ opacity */
  }
  .modal {
    z-index: 1050; /* Should be above the backdrop */
  }
  .modal-header {
    background-color: #4154f1;
    height: 20px;
  }

  .modal-title{
    color : #f6f6f6;
    font-size: 14px;
  }
  .icon-x{
    color : #f6f6f6;
    font-size: 24px;
    font-weight: bold;
  }

  .icon-x:hover{
    color : red;
    font-size: 24px;
    font-weight: bold;
  }