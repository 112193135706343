.refresh-button {
  padding-bottom: 5px;
}
.icon-refresh {
  font-size: 18px;
}
.activity-section {
  font-family: Arial, sans-serif;
  height: 200px; /* Set a fixed height */
  overflow-y: auto; /* Enable vertical scrolling */
}

.day-activity {
  display: flex;
  border-bottom: 1px solid #ddd;
  align-items: flex-start;
  margin-bottom: 40px;
}
.date-hari {
  font-size: 12px;
}
.date-section {
  text-align: right;
  margin-right: 20px;
  padding-right: 20px;
}

.date-day {
  font-size: 2em;
  font-weight: bold;
}

.date-month {
  font-size: 1em;
}

.activities {
  flex-grow: 1;
  margin-bottom: 40px;
  margin-left: 20px;
}

.activity-item {
  position: relative;
  padding-left: 0;
  margin-bottom: 15px;
}

.activity-time {
  margin-left: 10px;
  font-weight: bold;
}

.activity-dot {
  height: 10px;
  width: 10px;
  background-color: blue; /* Use your preferred color */
  border-radius: 50%;
  display: inline-block;
  margin-right: 5px;
  position: absolute;
  left: -25px; /* Adjust as necessary */
}
.activity-dot::after {
  content: "";
  position: absolute;
  left: 50%;
  top: 100%;
  height: 40px; /* Adjust the height as needed */
  width: 2px; /* Adjust the width as needed */
  background-color: #eceefe;
  transform: translateX(-50%);
}
.activity-description {
  margin-left: 10px;
  font-size: 14px;
}
