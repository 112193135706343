.refresh-button {
  padding-bottom: 5px;
}
.icon-refresh {
  font-size: 18px;
}
.profit-widget {
  width: 100%;
  height: 100%;
}
.profit-widget .profitChart {
  position: relative; /* Penting agar height dan width relatif bekerja */
  height: 150px; /* Atau berapapun yang Anda inginkan */
  width: 150px; /* Atau berapapun yang Anda inginkan */
}

.profit-detail {
  display: flex;
  margin-top: 40px; /* Atur sesuai kebutuhan */
  flex-direction: column;
}

.profit-detail span {
  font-size: 14px;
}

.color-box {
  display: inline-block;
  width: 10px; /* Atur ukuran kotak warna */
  height: 10px; /* Atur ukuran kotak warna */
  margin-right: 4px; /* Atur jarak kotak warna dari teks */
  border-radius: 50%; /* Membuat kotak menjadi bulat */
}

.data-profit {
  margin-top: 40px;
}

.data-profit span {
  font-size: 12px;
  font-weight: bolder;
}

.profit-data p {
  font-size: 18px;
  font-weight: bold;
}

.profit-desc p {
  font-size: 18px;
  font-weight: bold;
}
.value-profit p {
  font-weight: bold;
}

.section-revenue{
  margin-bottom: 50px;
  justify-content: end;
}
.section-profit{
  padding-top: 10px;
  border-top: 0.5px solid;
}